<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style lang="scss">
html, body {
  min-width: 320px;
}

#cookiescript_injected {
  left: initial !important;
  right: 20px;
}

.hero {
  background:  url('./assets/hero.png') no-repeat center right, linear-gradient(90deg, #1F2C55 45%, #53C0D8 100%);
  background-size: cover;
  @media (max-width: 992px) {
    background:  url('./assets/hero.png') no-repeat center right, linear-gradient(90deg, #1F2C55 70%, #53C0D8 100%);
  }
  @media (max-width: 768px) {
    background:  url('./assets/hero.png') no-repeat center right, linear-gradient(90deg, #1F2C55 70%, #53C0D8 100%);
  }
  @media (max-width: 576px) {
    background:  url('./assets/hero.png') no-repeat center right, linear-gradient(90deg, #1F2C55 100%, #53C0D8 100%);
  }
}
.logo {
  max-width: 220px;
}
.btn-pill {
  border-radius: 50px;
  padding: 0.5rem 2rem;
  font-size: 1.2rem;
  background-color: #00B37E;
}
.boxes {
  display: flex;
  align-items:stretch;
  justify-content: space-evenly;
  gap:1rem;
  flex-wrap: wrap;
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
    text-align: center;
    background: #fff;
    color:black;
    padding: 2rem 1rem;
    border-radius: 5px;
    margin: 1rem 0;
    @media (max-width: 450px) {
      flex: 1 1 100%;
      margin:0;
    }
    h3 {
      font-family: 'Montserrat-ExtraBold', sans-serif;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 1rem;
      margin:0;
    }
    &.box-loader {
      border-radius: 7px;
      overflow:clip;
      padding:0;
      background: none;
      min-width:120px;
      & > .loader {
        width:100% !important;
      }
    }
  }
}
.points {
  display:flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  .point {
    font-family: 'Montserrat-ExtraBold', sans-serif;
    span {
      font-size:1.25rem;
      @media (max-width: 576px) {
        font-size:1rem;
      }
    }
    img {
      margin-right:1rem;
      width: 3em;
      @media (max-width: 576px) {
        width: 2em;
      }
    }
  }
}
.hero-title, .hero-lead {
  font-family: 'Montserrat-ExtraBold', sans-serif;
}
.we-have-helped {
  h1, h2 {
    font-family: 'Montserrat-ExtraBold', sans-serif;
    line-height: 45px;
  }
  h1 {
    font-size: 4rem;
    line-height: 50px;
  }
  h2 {
    font-size: 1.4rem;
  }
  .piggy-bank {
    position: absolute;
    bottom:0;
    margin-left: 10em;
  }
  @media (max-width: 768px) {
    .piggy-bank {
      display: block;
      position: relative;
      margin: 0 auto;
      padding-bottom:0.5rem;
    }
  }
}


.name-enter-active, .name-leave-active {
  transition: margin-left .5s ease;
}
.name-enter {
  margin-left: 2rem;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
