export async function waitFor(ms) {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve();
    }, ms)
  );
}

export async function retry(fn, interval, max) {
  for (let i = 0; i < max; i++) {
    try {
      return await fn();
    } catch (e) {
      if (i === max - 1) {
        throw e;
      }
      await waitFor(interval);
    }
  }
}

export async function _apiRequest(
  method,
  url,
  body,
  responseType = "json",
  requestType = "json"
) {
  const req = {
    method: method,
  };
  if (body) {
    if (requestType === "json") {
      req.body = JSON.stringify(body);
      req.headers = {
        ...req.headers,
        "Content-Type": "application/json"
      };
    } else if (requestType === "file") {
      req.body = body;
    } else if (requestType === "html") {
      req.body = body;
      req.headers = {
        ...req.headers,
        "Content-Type": "text/html"
      };
    } else if (requestType === "text") {
      req.body = body;
      req.headers = {
        ...req.headers,
        "Content-Type": "text/plain"
      };
    }
  }
  return fetch(`/api/${url}`, req)
    .then(res => {
      if (res.status >= 200 && res.status < 300) {
        switch (responseType) {
          case "json":
            return res.json();
          case "text":
            return res.text();
          case "file":
            return res.blob();
        }
      }
      throw { status: res.status, response: res };
    })
    .catch(res => {
      if (res.response) {
        console.log(
          `Network error [${res.response.status}]: ${res.response.statusText} at ${res.response.url}`
        );
        res.response.text().then(text => {
          if (text) console.log(text);
        });
      }
      throw res;
    });
}
