<template>
    <div ref="appContainer">
      <div class="hero pt-4">
        <div class="container pb-5">
          <img :src="require('@/assets/logo-light.svg')" alt="Believe Loans" class="logo" />
          <h2 class="hero-title pt-4">Hello<Transition name="name"><span v-if="details">, {{ quote.name }}!</span></Transition></h2>
          <p class="hero-lead pt-4">Welcome to Believe Loans, the UK's number one loan broker</p>
          <div v-if="loading === false && details === null" class="hero-error">
            <h2 class="hero-title pt-3">Sorry we can't find your quote</h2>
            <p class="hero-lead pt-4">The link you followed isn't valid, we would suggest going back and clicking or copying the link.</p>
            <button class="btn btn-success btn-pill" @click="goBackToRefer">Go back</button>
          </div>
          <div v-else class="hero-quote">
            <h2 class="hero-title pt-3">Here is your loan offer...</h2>
            <transition name="slide-fade" mode="out-in">
              <div key="boxes-results" v-if="!loading && details !== null" class="boxes">
                <div class="box">
                  <h3>{{ quote.loanAmount }}</h3>
                  <p>Loan Amount</p>
                </div>
                <div class="box">
                  <h3>{{ quote.loanTerm }} months</h3>
                  <p>Loan Term</p>
                </div>
                <div class="box">
                  <h3>{{ quote.monthlyRepayment }}</h3>
                  <p>Monthly Repayment</p>
                </div>
                <div class="box">
                  <h3>{{ quote.totalRepayable }}</h3>
                  <p>Total Repayable</p>
                </div>
                <div class="box">
                  <h3>{{ quote.aprc }}</h3>
                  <p>APRC</p>
                </div>
              </div>
              <div key="boxes-loading" v-if="loading && details === null" class="boxes">
                <div class="box box-loader"><vue-skeleton-loader color="rgba(0, 0, 0, 0)" wave-color="rgba(83, 192, 216, 0.5)" /></div>
                <div class="box box-loader"><vue-skeleton-loader color="rgba(0, 0, 0, 0)" wave-color="rgba(83, 192, 216, 0.5)" /></div>
                <div class="box box-loader"><vue-skeleton-loader color="rgba(0, 0, 0, 0)" wave-color="rgba(83, 192, 216, 0.5)" /></div>
                <div class="box box-loader"><vue-skeleton-loader color="rgba(0, 0, 0, 0)" wave-color="rgba(83, 192, 216, 0.5)" /></div>
                <div class="box box-loader"><vue-skeleton-loader color="rgba(0, 0, 0, 0)" wave-color="rgba(83, 192, 216, 0.5)" /></div>
              </div>
            </transition>
            <div class="points pt-sm-3 pt-5">
              <div class="point">
                <img :src="require('@/assets/icons/tick.svg')" alt="Tick" />
                <span>Homeowner Exclusive</span>
              </div>
              <div class="point">
                <img :src="require('@/assets/icons/tick.svg')" alt="Tick" />
                <span>No up-front fees</span>
              </div>
              <div class="point">
                <img :src="require('@/assets/icons/tick.svg')" alt="Tick" />
                <span>Won't affect credit score</span>
              </div>
            </div>
            <p class="pt-5 pt-sm-4">By continuing below, you agree to the Believe Loans <a href="https://www.believeloans.com/terms" target="_blank">terms & conditions</a> and <a href="https://www.believeloans.com/privacy" target="_blank">privacy policy</a>.</p>
            <div class="d-inline-flex align-items-center mt-3">
              <button class="btn btn-success btn-pill" :disabled="loading || submitting" @click="letsGo">Let's go!</button>
              <svg style="margin-left:1rem;" v-if="submitting" width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                  <stop stop-color="#fff" stop-opacity="0" offset="0%"/>
                  <stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/>
                  <stop stop-color="#fff" offset="100%"/>
                </linearGradient>
              </defs>
              <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)">
                  <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="0.9s"
                        repeatCount="indefinite" />
                  </path>
                  <circle fill="#fff" cx="36" cy="18" r="1">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="0.9s"
                        repeatCount="indefinite" />
                  </circle>
                </g>
              </g>
            </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid bg-primary">
        <div class="container">
          <div class="row">
            <div class="col text-center pt-3 pb-2 we-have-helped position-relative">
              <h2>We have helped over</h2>
              <h1>20,000</h1>
              <h2>Customers</h2>
              <img class="piggy-bank" :src="require('@/assets/pig.png')" />
            </div>
          </div>
        </div>
      </div>
      <TrustPilot />
      <div class="container text-black-50">
      <p>
        This Indicative Quote does not constitute a mortgage offer and does not entitle its recipients to a mortgage advance from the lender. Exact figures and interest rates will be fully explained in your Mortgage Illustration, please ensure you fully read through this once you receive it. Any offer of a mortgage is subject to the prevailing terms and conditions and prior to the lenders full underwriting assessment.
      </p>
      <p>
        <strong>Representative Example</strong><br />
        If you borrow £30,000 over 180 months at a rate of 7.84% fixed for 5 years, you will pay 60 instalments of £309.72 per month followed by 120 months at a rate of 8.59% of £319.96 and a total amount payable of £57,153.40. This includes a broker fee of £1,995, a lender fee of £695 and the net loan interest of £24,253.85. The overall cost for comparison is 8.70% APRC Variable.
      </p>
      <p>
        <strong
        >Your home may be repossessed if you do not keep up repayments on your mortgage.</strong
        >
      </p>
      </div>
    </div>
</template>

<script>
import TrustPilot from '../components/TrustPilot.vue'
import VueSkeletonLoader from 'skeleton-loader-vue'
// eslint-disable-next-line no-unused-vars
import { _apiRequest, waitFor } from '../data/apiUtils'
export default {
  name: 'Home',
  components: {
    TrustPilot,
    VueSkeletonLoader
  },
  data() {
    return {
      loading: true,
      details: null,
      submitting: false
    }
  },
  computed: {
    id() {
      return this.$route?.params?.id ?? ""
    },
    quote() {
      return {
        name: this.details?.name,
        loanAmount: this.formatCurrency(this.details?.quote?.loanAmount, 0),
        loanTerm: this.details?.quote?.loanTerm,
        monthlyRepayment: this.formatCurrency(this.details?.quote?.monthlyRepayment),
        totalRepayable: this.formatCurrency(this.details?.quote?.totalRepayable),
        aprc: this.formatPercentage(this.details?.quote?.aprc)
      }
    }
  },
  async mounted() {
    await this.getConsentDetails();
  },
  methods: {
    //
    // Buttons
    //
    goBackToRefer() {
      history.back();
    },
    async letsGo() {
      this.submitting = true;
      await this.setConsent();
    },
    //
    // Get/Set Consent
    //
    async getConsentDetails() {
      this.loading = true;
      try {
        this.details = await _apiRequest('GET',`leads/quote/${this.id}`, null);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async setConsent() {
      try {
        let result = await _apiRequest('POST',`leads/quote/${this.id}`, null);
        if (result?.redirect) {
          await this.$router.push({name: 'Redirect', params: {url: result.redirect}});
          return;
        }
      } catch (e) {
        console.error(e);
      }
      await this.$router.push({name: 'ThankYou'});
    },
    //
    // Utils
    //
    formatCurrency(value, minimumFractionDigits = 2) {
      return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits }).format(value);
    },
    formatPercentage(value) {
      return `${value}%`
    }
  }
}
</script>
