<template>
  <div class="hero d-flex align-items-stretch justify-content-center flex-column h-100">
    <div class="text-center">
      <h2 class="hero-title pt-4">Redirecting</h2>
      <p class="hero-lead pt-4">Please wait..</p>
      <svg width="60" height="60" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
            <stop stop-color="#fff" stop-opacity="0" offset="0%"/>
            <stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/>
            <stop stop-color="#fff" offset="100%"/>
          </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)">
            <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
              <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.9s"
                  repeatCount="indefinite" />
            </path>
            <circle fill="#fff" cx="36" cy="18" r="1">
              <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.9s"
                  repeatCount="indefinite" />
            </circle>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Redirecting',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  mounted() {
    setTimeout(() => {
      window.location.href = this.url ?? 'https://www.believeloans.com';
    }, 250);
  }
}
</script>

<style lang="scss" scoped>
.hero {
  min-height: 100vh;
}
</style>