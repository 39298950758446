<template>
  <div class="thank-you">
    <div class="hero pt-4 d-flex align-items-stretch justify-content-center flex-column h-100">
      <div class="text-center">
        <img :src="require('@/assets/logo-light.svg')" alt="Believe Loans" class="logo" />
        <h2 class="hero-title pt-4">Thank You!</h2>
        <p class="hero-lead pt-4">Believe Loans, the UK's number one loan broker</p>
        <p class="hero-lead pt-3">One of our award winning team will be calling you soon.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThankYou',
}
</script>

<style lang="scss" scoped>
.hero {
  min-height: 100vh;
}
</style>