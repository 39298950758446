import Home from './views/Home.vue';
import ThankYou from "./views/ThankYou.vue";
import Redirect from "./views/Redirect.vue";

export const routes = [
    {
        name: "Home",
        path: "/:id?",
        component: Home
    },
    {
        name: "Redirect",
        path: "/quote/redirecting",
        component: Redirect,
        props: true
    },
    {
        name: "ThankYou",
        path: "/quote/thank-you",
        component: ThankYou
    }
]
